body {
    &.active {
        overflow: hidden;

        &::before {
            pointer-events: auto;
            z-index: 10;
            content: "";
            display: block;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($black,.5);
        }
    }
}
