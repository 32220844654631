.awards {
	@include background-color($grey-1);
	padding: 64px 20px;

	.night & {
		@include background-color(#2e3b4b);
	}
}

.awards-list {
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;

	& > * {
		width: 300px;
	}
}

.award-card {
    &.--inactive {
        display: none;
    }

	.title {
		font-family: $gentium;
		font-size: 32px;
		line-height: 44px;
		color: $color-1;
	}
}
