@function line-height($size, $height) {
	@return #{$height / $size};
}

@function layout($cols,$responsive:true) {
	@if $responsive {
		@return #{(($grid-column * $cols) / $grid-size) * 100%};
	}
	@else {
		@return #{$grid-column * $cols};
	}
}
