$darkmode-diameter: 24px;
$darkmode-safearea: 2px;

[data-toggle="darkmode"] {
    position: relative;
    padding: 0 !important;
    width: #{$darkmode-diameter * 3 / 2};
    height: $darkmode-diameter;
    background-color: $color-1;
    border-radius: $darkmode-diameter;
    text-indent: -9999px;

    &::before {
        @include pseudo-element;
        position: absolute;
        top: 50%;
        left: $darkmode-safearea;
        transform: translate(0,-50%);
        width: #{$darkmode-diameter - ($darkmode-safearea * 2)};
        height: #{$darkmode-diameter - ($darkmode-safearea * 2)};
        background-color: $white;
        border-radius: 50%;
        transition: all .1s ease;
    }

    .night & {
        background-color: $grey-1;

        &::before {
            left: calc(100% - #{$darkmode-safearea});
            transform: translate(-100%,-50%);
            background-color: $black;
        }
    }
}
