.site-main {
    position: relative;
    border-left: 1px solid var(--grid);
    border-right: 1px solid var(--grid);

    &::before,
    &::after {
        z-index: -1;
        @include pseudo-element;
        position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
    }

    @media screen and (min-width:860px){
        &::before {
            width: layout(5);
            border-left: 1px solid var(--grid);
            border-right: 1px solid var(--grid);
        }
    }

    &::after {
        // width: layout(3);
        border-left: 1px solid var(--grid);
        border-right: none;
    }
}
