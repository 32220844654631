.navigation {
    padding: 60px 0;
	width: 100%;
}

.navigation-list {
	display: inline-flex;
	justify-content: flex-end;
	position: relative;
	left: -10px;
	width: calc(100% + 20px);

	& > * {
		margin: 0 10px;
	}
}

.menu-item {
    a {
        letter-spacing: .5px;
    }
}

//

.info-nav {
    text-align: right;
}

.info-nav-label {
    margin: 4px 0;
    font-size: 12px;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.info-nav-status {
    margin: 4px 0;
    font-family: $gentium;
    font-size: 20px;
    color: $color-1;
}
