::selection {
    color: $white;
    background-color: $black;
}

::placeholder {
    color: rgba($black,.3);
}

body {
	font-family: $hindguntur;
	font-weight: 300;
	font-size: 1.7rem;
	color: var(--black, $black);
	background-color: var(--white, $white);
}

p {
    margin: (1em * 1.3) 0;
	line-height: line-height(17,25);
}

%wrap {
	margin: 0 auto;
	max-width: 1140px;
}

.site {
	overflow: hidden;
	margin: 0 auto;
}

.site-header,
.site-main,
.site-footer {
    @extend %wrap;
	position: relative;
	margin: 0 auto;
	width: calc(100% - 80px);
}
