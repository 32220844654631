$input-padding: 10px;
$input-width: 100% !default;
$input-font-size: 1em !default;
$input-background-color: rgba($grey-1,.2);
$input-border: 1px solid $input-background-color;

$input-border-focus: $color-1;

%input {
    display: inline-block;
    padding: $input-padding;
    width: $input-width;
    font-family: inherit;
    font-size: $input-font-size;
    background-color: $input-background-color;
    border: $input-border;
    appearance: none;

    &:focus {
        border-color: $input-border-focus;
    }
}

[type="date"] {
    @extend %input;
}

[type="email"] {
    @extend %input;
}

[type="number"] {
    @extend %input;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
    }
}

[type="text"] {
    @extend %input;
}

//

.form-field {
    margin: 30px 0;
}

.--submit {
    display: flex;
    justify-content: flex-end;
}
