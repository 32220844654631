
@keyframes hello {
    from {
        transform: rotate(-20deg);
    }
    from {
        transform: rotate(20deg);
    }

}
.icon-hello {
    display: inline-block;
    margin-left: 6px;
    transform-origin: 75% 75%;

    .link:hover + & {
        animation: hello .3s alternate-reverse ease-in-out infinite;
    }
}
