%colored {
	color: $white;
    background-color: $color-1;

	.banner-subtitle {
		color: $black;
	}

	.awards {
		&::before {
			background-color: lighten($color-1,10%);
		}

		.title {
			color: $black;
		}
	}
}
