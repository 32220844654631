$checkbox-border: 1px solid $grey-1;
$checkbox-radius: 1px;

[type="checkbox"] {
	display: none;
}

[type="checkbox"] + label {
	position: relative;
	margin-left: 10px;
	padding-left: calc(1em + 7px);
	cursor: pointer;
	transition: all .2s ease;

	&::before,
	&::after {
		@include pseudo-element;
		display: block;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		width: 1em;
		height: 1em;
		border: $checkbox-border;
	}

	&::after {
		transform: translateY(-50%) scale(0);
		background: $color-1;
		border-color: transparent;
	}
}

[type="checkbox"]:checked + label {
	&::before {
		border-color: $color-1;
	}

	&::after {
		transform: translateY(-50%) scale(.66);
	}
}

[type="checkbox"] + label {
	&::before,
	&::after {
		border-radius: $checkbox-radius;
	}
}
