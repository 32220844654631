@keyframes link {
    0% {
      transform: scaleX(1);
      transform-origin: right center;
    }
    40% {
      transform: scaleX(0);
      transform-origin: right center;
    }
    60% {
      transform: scaleX(0);
      transform-origin: left center;
    }
    100% {
        transform: scaleX(1);
        transform-origin: left center;
    }
  }

.link {
    position: relative;
    margin: 30px 0 0;
	padding: 0 0 .3em;
    font-family: $gentium;
    font-style: italic;
    font-size: 1.2em;
    text-align: right;
    // color: $color-1;

	&::before {
		content: "";
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1px;
		background-color: currentColor;
	}

	&:hover {
		&::before {
		    animation: link .5s .1s ease-in-out;
        }
	}
}
