$abbr-margin: 0;
$abbr-color: inherit;

abbr {
    margin: $abbr-margin;
    color: $abbr-color;
}

label {
    display: block;
    margin: 10px 0;
    font-size: 12px;
    letter-spacing: 3px;
    text-transform: uppercase;
}
