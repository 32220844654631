.banner {
    display: flex;
    flex-direction: column-reverse;

    @media screen and (min-width:860px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        & > * {
            width: 50%;
        }
    }
}

.banner-presentation-title {

}

.banner-presentation {
    padding: 40px 0;

    & > * {
        margin-left: auto;
        margin-right: auto;
    }

    @media screen and (min-width:520px) {
        & > * {
            max-width: layout(8);
        }
    }

    @media screen and (min-width:860px) {
        padding-right: 30px;
        margin: 10vh 0 0;

        & > * {
            margin-left: 0;
            margin-right: 0;
            max-width: layout(5,false);
        }
    }
}

.banner-project {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media screen and (max-width:860px) {
        margin-top: 50px;
    }

    @media screen and (min-width:860px) {
        height: 100vh;
    }
}

.banner-title {
	font-family: $gentium;
    font-weight: 400;
    font-size: 2.8rem;

    @media screen and (min-width:520px) {
        font-size: 4.4rem;
    }

    @media screen and (max-width:860px) {
        z-index: 1;
        position: absolute;
        top: 33px;
        left: 10px;
    }

    @media screen and (min-width:860px) {
        font-size: 9.6rem;
    }
}

.banner-subtitle {
    margin: 0 0 40px;
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 3px;
	text-transform: uppercase;
    color: $color-1;

    @media screen and (min-width:860px) {
        font-size: 2rem;
    }
}

.banner-content {
    @media screen and (min-width:860px) {
        @include cols-item(4,10px);
    }
}

.num-of-slides {
    position: absolute;
    right: 0;
    top: 25px;
    transform: translateX(calc(100% - 40px));
    font-family: $gentium;

    #current-slide {
        font-size: 2em;
    }

    @media screen and (min-width:860px) {
        top: 15vh;
    }
}

.action-on-slides {
    display: flex;
    align-items:center;
    position: absolute;
    top: 60px;
    right: 0;
    transform: translateX(50%);

    #prev,
    #next {
        display: inline-block;
        transform: rotate(45deg);
        margin: 0 10px;
        width: 12px;
        height: 12px;
        border: 2px solid currentColor;
        border-radius: 1px;
        text-indent: -9999px;
        transition: all .2s ease-in-out;
        cursor: pointer;
        opacity: .5;

        &:hover {
            opacity: 1;
        }
    }

    #prev {
        border-top: none;
        border-right: none;
    }

    #next {
        border-bottom: none;
        border-left: none;
    }
}
