$black: #24292f;
$white: #ffffff;
$grey-1: #edeff1;

$color-1: #117af4;

:root {
	.day {
		--black: #{$black};
        --white: #{$white};
        --grid: #{rgba($black,.05)};
	}

	.night {
		--black: #{$white};
        --white: #{$black};
        --grid: #{rgba($white,.05)};
	}
}
