.projects {
    // overflow: hidden;
    position: relative;
    width: 100%;
    height: 60vh;

    @media screen and (min-width:520px) {
        max-width: layout(4,false);
        height: 80vh;
    }
}

@keyframes projectIn {
    from {
        transform: translateX(100%);
        opacity: 0;
        pointer-events: none;
    }
    to {
        transform: translateX(0);
        opacity: 1;
        pointer-events: auto;
    }
}

@keyframes projectOut {
    from {
        transform: translateX(0);
        opacity: 1;
        pointer-events: auto;
    }
    to {
        transform: translateX(-100%);
        opacity: 0;
        pointer-events: none;
    }
}

.project {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;

    a {
        display: block;
        width: 100%;
        height: 100%;
    }

    &.--active {
        animation: projectIn .5s ease-in-out both;
    }

    &.--inactive {
        animation: projectOut .5s ease-in-out both;
    }

    .title {
        transform: translateX(-20px);
        font-family: $gentium;
        font-size: 32px;
    }

    p {
        margin: 1em 0;
        width: 85%;
    }
}

.project-image {
    width: 100%;
    height: 100%;
    transition: all .2s ease-in-out;

    @media screen and (min-width:860px) {
        .project:hover & {
            height: 85%;

            img {
                transform: translateY(-60px);
                opacity: .2;
            }
        }
    }

    img {
        margin: 0 auto;
        transition: inherit;
    }
}

.project:nth-child(1) {
    img {
        position: absolute;
        left: 0;
        top: -20px;
        width: 413px;
        height: 642px;

        @media screen and (max-width:600px) {
            left: 50px;
            width: 310px;
            height: 482px;
        }
    }
}

.project:nth-child(2) {
    img {
        position: absolute;
        left: -120px;
        bottom: 0;
        width: 600px;
        height: 546px;

        @media screen and (max-width:600px) {
            left: -60px;
            width: 450px;
            height: 410px;
        }
    }
}

.project-content {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-left: 30px;

    @media screen and (max-width:860px) {
        bottom: 40px;

        .title,
        p {
            display: none;
        }
    }

    @media screen and (min-width:860px) {
        transform: translateY(20px);
        opacity: 0;
        transition: all .3s ease-out, opacity .1s ease-in-out;

        .project:hover & {
            transform: translateY(0);
            opacity: 1;
            transition-delay: .15s;
        }
    }
}
