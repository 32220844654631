.logo {
	font-family: $gentium;
	font-weight: 400;
    font-size: 3rem;

    &.menu-item {
        margin-left: 0;
	    margin-right: auto;
    }
}
