$input-padding: 10px;
$input-width: 100% !default;
$input-font-size: 1em !default;
$input-background-color: rgba($grey-1,.2);
$input-border: none;

$input-border-focus: 1px solid $color-1;

textarea {
    display: inline-block;
    padding: $input-padding;
    width: $input-width;
    max-height: 420px;
    font-family: inherit;
    font-size: $input-font-size;
    background-color: $input-background-color;
    border: $input-border;
    resize: vertical;
    appearance: none;

    &::focus {
        border-bottom: $input-border-focus;
    }
}
