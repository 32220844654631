.social {
	position: fixed;
	top: 50%;
	right: 30px;
	transform: translateY(-50%);
}

.social-list {
    display: flex;
	flex-direction: column;
	justify-content: center;
}

.social-item {
	margin: 10px 0;

    .night & svg * {
        fill: $white;
    }
}

