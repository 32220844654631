:root .day {
  --black: #24292f;
  --white: #ffffff;
  --grid: rgba(36, 41, 47, 0.05);
}

:root .night {
  --black: #ffffff;
  --white: #24292f;
  --grid: rgba(255, 255, 255, 0.05);
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*, *::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
}

*::before,
*::after {
  outline: none;
  pointer-events: none;
}

html {
  font-size: 62.5%;
}

a {
  text-decoration: none;
  cursor: pointer;
}

button,
[type="button"],
[type="submit"] {
  cursor: pointer;
}

::selection {
  color: #ffffff;
  background-color: #24292f;
}

::placeholder {
  color: rgba(36, 41, 47, 0.3);
}

body {
  font-family: "Hind Guntur", sans-serif;
  font-weight: 300;
  font-size: 1.7rem;
  color: var(--black, #24292f);
  background-color: var(--white, #ffffff);
}

p {
  margin: 1.3em 0;
  line-height: 1.47059;
}

.site-header,
.site-main,
.site-footer {
  margin: 0 auto;
  max-width: 1140px;
}

.site {
  overflow: hidden;
  margin: 0 auto;
}

.site-header,
.site-main,
.site-footer {
  position: relative;
  margin: 0 auto;
  width: calc(100% - 80px);
}

.site-footer > * {
  padding: 20px 0;
}

a {
  display: inline-block;
}

body.active {
  overflow: hidden;
}

body.active::before {
  pointer-events: auto;
  z-index: 10;
  content: "";
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(36, 41, 47, 0.5);
}

button {
  font: inherit;
  background-color: transparent;
  border: none;
}

[type="date"], [type="email"], [type="number"], [type="text"] {
  display: inline-block;
  padding: 10px;
  width: 100%;
  font-family: inherit;
  font-size: 1em;
  background-color: rgba(237, 239, 241, 0.2);
  border: 1px solid rgba(237, 239, 241, 0.2);
  appearance: none;
}

:focus[type="date"], :focus[type="email"], :focus[type="number"], :focus[type="text"] {
  border-color: #117af4;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

.form-field {
  margin: 30px 0;
}

.--submit {
  display: flex;
  justify-content: flex-end;
}

[type="checkbox"] {
  display: none;
}

[type="checkbox"] + label {
  position: relative;
  margin-left: 10px;
  padding-left: calc(1em + 7px);
  cursor: pointer;
  transition: all .2s ease;
}

[type="checkbox"] + label::before, [type="checkbox"] + label::after {
  content: "";
  display: block;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 1em;
  height: 1em;
  border: 1px solid #edeff1;
}

[type="checkbox"] + label::after {
  transform: translateY(-50%) scale(0);
  background: #117af4;
  border-color: transparent;
}

[type="checkbox"]:checked + label::before {
  border-color: #117af4;
}

[type="checkbox"]:checked + label::after {
  transform: translateY(-50%) scale(0.66);
}

[type="checkbox"] + label::before, [type="checkbox"] + label::after {
  border-radius: 1px;
}

[type="radio"] {
  display: none;
}

[type="radio"] + label {
  position: relative;
  margin-left: 10px;
  padding-left: calc(1em + 7px);
  cursor: pointer;
  transition: all .2s ease;
}

[type="radio"] + label::before, [type="radio"] + label::after {
  content: "";
  display: block;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 1em;
  height: 1em;
  border: 1px solid #edeff1;
}

[type="radio"] + label::after {
  transform: translateY(-50%) scale(0);
  background: #117af4;
  border-color: transparent;
}

[type="radio"]:checked + label::before {
  border-color: #117af4;
}

[type="radio"]:checked + label::after {
  transform: translateY(-50%) scale(0.66);
}

[type="radio"] + label::before, [type="radio"] + label::after {
  border-radius: 50%;
}

[type="range"] {
  width: 100%;
  background-color: transparent;
  appearance: none;
}

[type="range"]:focus {
  outline: none;
}

[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animation: 0.2s;
  border: 0 solid #000;
  border-radius: 4px;
  background: #edeff1;
}

[type="range"]::-webkit-slider-thumb {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  cursor: pointer;
  border: 0 solid #000;
  border-radius: 100%;
  background: #117af4;
  -webkit-appearance: none;
  margin-top: -0 * 24px/-6px;
}

[type="range"]:focus::-webkit-slider-runnable-track {
  background: #edeff1;
}

[type="range"]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animation: 0.2s;
  border: 0 solid #000;
  border-radius: 4px;
  background: #edeff1;
}

[type="range"]::-moz-range-thumb {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  cursor: pointer;
  border: 0 solid #000;
  border-radius: 100%;
  background: #117af4;
}

[type="range"]::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animation: 0.2s;
  color: transparent;
  border-width: 16px 0;
  border-color: transparent;
  background: transparent;
}

[type="range"]::-ms-fill-lower {
  border: 0 solid #000;
  border-radius: 8px;
  background: #edeff1;
}

[type="range"]::-ms-fill-upper {
  border: 0 solid #000;
  border-radius: 8px;
  background: #edeff1;
}

[type="range"]::-ms-thumb {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  cursor: pointer;
  border: 0 solid #000;
  border-radius: 100%;
  background: #117af4;
}

[type="range"]:focus::-ms-fill-lower {
  background: #edeff1;
}

[type="range"]:focus::-ms-fill-upper {
  background: #edeff1;
}

abbr {
  margin: 0;
  color: inherit;
}

label {
  display: block;
  margin: 10px 0;
  font-size: 12px;
  letter-spacing: 3px;
  text-transform: uppercase;
}

textarea {
  display: inline-block;
  padding: 10px;
  width: 100%;
  max-height: 420px;
  font-family: inherit;
  font-size: 1em;
  background-color: rgba(237, 239, 241, 0.2);
  border: none;
  resize: vertical;
  appearance: none;
}

textarea::focus {
  border-bottom: 1px solid #117af4;
}

@keyframes link {
  0% {
    transform: scaleX(1);
    transform-origin: right center;
  }
  40% {
    transform: scaleX(0);
    transform-origin: right center;
  }
  60% {
    transform: scaleX(0);
    transform-origin: left center;
  }
  100% {
    transform: scaleX(1);
    transform-origin: left center;
  }
}

.link {
  position: relative;
  margin: 30px 0 0;
  padding: 0 0 .3em;
  font-family: "Gentium Basic", serif;
  font-style: italic;
  font-size: 1.2em;
  text-align: right;
}

.link::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: currentColor;
}

.link:hover::before {
  animation: link .5s .1s ease-in-out;
}

.logo {
  font-family: "Gentium Basic", serif;
  font-weight: 400;
  font-size: 3rem;
}

.logo.menu-item {
  margin-left: 0;
  margin-right: auto;
}

@keyframes hello {
  from {
    transform: rotate(-20deg);
  }
  from {
    transform: rotate(20deg);
  }
}

.icon-hello {
  display: inline-block;
  margin-left: 6px;
  transform-origin: 75% 75%;
}

.link:hover + .icon-hello {
  animation: hello .3s alternate-reverse ease-in-out infinite;
}

.menu {
  z-index: 15;
  position: fixed;
  top: 0;
  right: 0;
  padding: 40px 30px 80px;
  width: calc(100% - 40px);
  max-width: 440px;
  height: 100%;
  color: #ffffff;
  background-color: #24292f;
  transform: translateX(100%);
  transition: all .2s ease-out;
}

.menu.active {
  transform: translateX(0);
}

.btn-menu {
  z-index: 20;
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 40px;
  height: 40px;
  color: #117af4;
  text-indent: -9999px;
}

.btn-menu::before, .btn-menu::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 1px;
  background-color: var(--black);
}

.day .btn-menu.active::before, .day .btn-menu.active::after {
  background-color: var(--white);
}

.night .btn-menu.active::before, .night .btn-menu.active::after {
  background-color: var(--black);
}

.btn-menu::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.btn-menu::after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.btn-menu:hover {
  transform: rotate(-90deg);
  transition: all .2s .1s ease-in-out;
}

.awards {
  position: relative;
  padding: 64px 20px;
}

.awards::before {
  z-index: -1;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100vw - 80px);
  height: 100%;
  background-color: #edeff1;
}

.night .awards {
  position: relative;
}

.night .awards::before {
  z-index: -1;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100vw - 80px);
  height: 100%;
  background-color: #2e3b4b;
}

.awards-list {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.awards-list > * {
  width: 300px;
}

.award-card.--inactive {
  display: none;
}

.award-card .title {
  font-family: "Gentium Basic", serif;
  font-size: 32px;
  line-height: 44px;
  color: #117af4;
}

.banner {
  display: flex;
  flex-direction: column-reverse;
}

@media screen and (min-width: 860px) {
  .banner {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .banner > * {
    width: 50%;
  }
}

.banner-presentation {
  padding: 40px 0;
}

.banner-presentation > * {
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 520px) {
  .banner-presentation > * {
    max-width: 66.66667%;
  }
}

@media screen and (min-width: 860px) {
  .banner-presentation {
    padding-right: 30px;
    margin: 10vh 0 0;
  }
  .banner-presentation > * {
    margin-left: 0;
    margin-right: 0;
    max-width: 475px;
  }
}

.banner-project {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

@media screen and (max-width: 860px) {
  .banner-project {
    margin-top: 50px;
  }
}

@media screen and (min-width: 860px) {
  .banner-project {
    height: 100vh;
  }
}

.banner-title {
  font-family: "Gentium Basic", serif;
  font-weight: 400;
  font-size: 2.8rem;
}

@media screen and (min-width: 520px) {
  .banner-title {
    font-size: 4.4rem;
  }
}

@media screen and (max-width: 860px) {
  .banner-title {
    z-index: 1;
    position: absolute;
    top: 33px;
    left: 10px;
  }
}

@media screen and (min-width: 860px) {
  .banner-title {
    font-size: 9.6rem;
  }
}

.banner-subtitle {
  margin: 0 0 40px;
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #117af4;
}

@media screen and (min-width: 860px) {
  .banner-subtitle {
    font-size: 2rem;
  }
}

@media screen and (min-width: 860px) {
  .banner-content {
    display: flex;
    position: relative;
    left: -10px;
    width: calc(100% + 20px);
  }
  .banner-content > * {
    margin: 10px;
    width: calc(33.33333% - 20px);
  }
}

.num-of-slides {
  position: absolute;
  right: 0;
  top: 25px;
  transform: translateX(calc(100% - 40px));
  font-family: "Gentium Basic", serif;
}

.num-of-slides #current-slide {
  font-size: 2em;
}

@media screen and (min-width: 860px) {
  .num-of-slides {
    top: 15vh;
  }
}

.action-on-slides {
  display: flex;
  align-items: center;
  position: absolute;
  top: 60px;
  right: 0;
  transform: translateX(50%);
}

.action-on-slides #prev,
.action-on-slides #next {
  display: inline-block;
  transform: rotate(45deg);
  margin: 0 10px;
  width: 12px;
  height: 12px;
  border: 2px solid currentColor;
  border-radius: 1px;
  text-indent: -9999px;
  transition: all .2s ease-in-out;
  cursor: pointer;
  opacity: .5;
}

.action-on-slides #prev:hover,
.action-on-slides #next:hover {
  opacity: 1;
}

.action-on-slides #prev {
  border-top: none;
  border-right: none;
}

.action-on-slides #next {
  border-bottom: none;
  border-left: none;
}

.navigation {
  padding: 60px 0;
  width: 100%;
}

.navigation-list {
  display: inline-flex;
  justify-content: flex-end;
  position: relative;
  left: -10px;
  width: calc(100% + 20px);
}

.navigation-list > * {
  margin: 0 10px;
}

.menu-item a {
  letter-spacing: .5px;
}

.info-nav {
  text-align: right;
}

.info-nav-label {
  margin: 4px 0;
  font-size: 12px;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.info-nav-status {
  margin: 4px 0;
  font-family: "Gentium Basic", serif;
  font-size: 20px;
  color: #117af4;
}

.social {
  position: fixed;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}

.social-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.social-item {
  margin: 10px 0;
}

.night .social-item svg * {
  fill: #ffffff;
}

.projects {
  position: relative;
  width: 100%;
  height: 60vh;
}

@media screen and (min-width: 520px) {
  .projects {
    max-width: 380px;
    height: 80vh;
  }
}

@keyframes projectIn {
  from {
    transform: translateX(100%);
    opacity: 0;
    pointer-events: none;
  }
  to {
    transform: translateX(0);
    opacity: 1;
    pointer-events: auto;
  }
}

@keyframes projectOut {
  from {
    transform: translateX(0);
    opacity: 1;
    pointer-events: auto;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
    pointer-events: none;
  }
}

.project {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.project a {
  display: block;
  width: 100%;
  height: 100%;
}

.project.--active {
  animation: projectIn .5s ease-in-out both;
}

.project.--inactive {
  animation: projectOut .5s ease-in-out both;
}

.project .title {
  transform: translateX(-20px);
  font-family: "Gentium Basic", serif;
  font-size: 32px;
}

.project p {
  margin: 1em 0;
  width: 85%;
}

.project-image {
  width: 100%;
  height: 100%;
  transition: all .2s ease-in-out;
}

@media screen and (min-width: 860px) {
  .project:hover .project-image {
    height: 85%;
  }
  .project:hover .project-image img {
    transform: translateY(-60px);
    opacity: .2;
  }
}

.project-image img {
  margin: 0 auto;
  transition: inherit;
}

.project:nth-child(1) img {
  position: absolute;
  left: 0;
  top: -20px;
  width: 413px;
  height: 642px;
}

@media screen and (max-width: 600px) {
  .project:nth-child(1) img {
    left: 50px;
    width: 310px;
    height: 482px;
  }
}

.project:nth-child(2) img {
  position: absolute;
  left: -120px;
  bottom: 0;
  width: 600px;
  height: 546px;
}

@media screen and (max-width: 600px) {
  .project:nth-child(2) img {
    left: -60px;
    width: 450px;
    height: 410px;
  }
}

.project-content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding-left: 30px;
}

@media screen and (max-width: 860px) {
  .project-content {
    bottom: 40px;
  }
  .project-content .title,
  .project-content p {
    display: none;
  }
}

@media screen and (min-width: 860px) {
  .project-content {
    transform: translateY(20px);
    opacity: 0;
    transition: all .3s ease-out, opacity .1s ease-in-out;
  }
  .project:hover .project-content {
    transform: translateY(0);
    opacity: 1;
    transition-delay: .15s;
  }
}

form {
  margin: 0 auto;
  max-width: 50%;
}

.apropos {
  color: #ffffff;
  background-color: #117af4;
}

.apropos .banner-subtitle {
  color: #24292f;
}

.apropos .awards::before {
  background-color: #4295f6;
}

.apropos .awards .title {
  color: #24292f;
}

#_cursor {
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, -50%) scale(0);
  width: 60px;
  height: 60px;
  background-color: rgba(17, 122, 244, 0.1);
  border-radius: 50%;
  transition: transform .2s ease-in-out;
}

.--is_colored #_cursor {
  background-color: rgba(36, 41, 47, 0.1);
}

#_cursor.move {
  transform: translate(-50%, -50%) scale(1);
  transition: transform .1s ease-in-out;
}

#_cursor.hover {
  transform: translate(-50%, -50%) scale(2);
}

[data-toggle="darkmode"] {
  position: relative;
  padding: 0 !important;
  width: 36px;
  height: 24px;
  background-color: #117af4;
  border-radius: 24px;
  text-indent: -9999px;
}

[data-toggle="darkmode"]::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translate(0, -50%);
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border-radius: 50%;
  transition: all .1s ease;
}

.night [data-toggle="darkmode"] {
  background-color: #edeff1;
}

.night [data-toggle="darkmode"]::before {
  left: calc(100% - 2px);
  transform: translate(-100%, -50%);
  background-color: #24292f;
}

.site-main {
  position: relative;
  border-left: 1px solid var(--grid);
  border-right: 1px solid var(--grid);
}

.site-main::before, .site-main::after {
  z-index: -1;
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
}

@media screen and (min-width: 860px) {
  .site-main::before {
    width: 41.66667%;
    border-left: 1px solid var(--grid);
    border-right: 1px solid var(--grid);
  }
}

.site-main::after {
  border-left: 1px solid var(--grid);
  border-right: none;
}
