#_cursor {
    pointer-events: none;
    position: absolute;
    transform: translate(-50%,-50%) scale(0);
    width: 60px;
    height: 60px;
    background-color: rgba($color-1,.1);
    border-radius: 50%;
    transition: transform .2s ease-in-out;

    .--is_colored & {
        background-color: rgba($black,.1);
    }

    &.move {
        transform: translate(-50%,-50%) scale(1);
        transition: transform .1s ease-in-out;
    }

    &.hover {
        transform: translate(-50%,-50%) scale(2);
    }
}
