.menu {
    z-index: 15;
    position: fixed;
    top: 0;
    right: 0;
    padding: 40px 30px 80px;
    width: calc(100% - 40px);
    max-width: 440px;
    height: 100%;
    color: $white;
    background-color: $black;
    transform: translateX(100%);
    transition: all .2s ease-out;

    &.active {
        transform: translateX(0);
    }
}

.btn-menu {
    z-index: 20;
    position: fixed;
	bottom: 30px;
    right: 30px;
    width: 40px;
    height: 40px;
    color: $color-1;
    text-indent: -9999px;

    &::before,
    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 1px;
        background-color: var(--black);
    }

    &.active {
        &::before,
        &::after {
            .day & {
                background-color: var(--white);
            }

            .night & {
                background-color: var(--black);
            }
        }
    }
        &::before {
            transform: translate(-50%,-50%) rotate(-45deg);
        }

        &::after {
            transform: translate(-50%,-50%) rotate(45deg);
        }
    // }

    &:hover {
        transform: rotate(-90deg);
        transition: all .2s .1s ease-in-out;
    }
}
