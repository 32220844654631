$mixin-bgc-width: true;
@mixin background-color($color, $max-width:$mixin-bgc-width) {
	position: relative;

	&::before {
		z-index: -1;
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		@if $max-width {
			width: calc(100vw - #{$grid-offset * 2});
		}
		@else {
			width: 100vw;
		}
		height: 100%;
		background-color: $color;
	}
}

// Call what u need to make a ::before or ::after
@mixin pseudo-element {
    content: "";
    display: block;
}

@mixin cards-item($cards,$margin) {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: flex-start;
	position: relative;
	left: -#{$margin};
	width: calc(100% + #{$margin * 2});

	& > * {
		margin: #{$margin};
		width: calc(#{100% / $cards} - #{$margin * 2});
	}
}

@mixin cols-item($cols,$margin) {
	display: flex;
	position: relative;
	left: -#{$margin};
	width: calc(100% + #{$margin * 2});

	& > * {
		margin: #{$margin};
		width: calc(#{layout(4)} - #{$margin * 2});
	}
}
