%clean {
    box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: inherit;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: none;
}

* {
	@extend %clean;
}

*::before,
*::after {
	@extend %clean;
	outline: none;
	pointer-events: none;
}

html {
	font-size: 62.5%;
}

a {
	text-decoration: none;
	cursor: pointer;
}

button,
[type="button"],
[type="submit"] {
	cursor: pointer;
}
